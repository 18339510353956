import React from "react";

import './NoapteaMuzeelor.css'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import NoapteaMuzeelorPic from '../../utils/images/NoapteaMuzeelor.jpg'
import { useMediaQuery } from 'react-responsive';

const NoapteaMuzeelor = () => {

    const xs = useMediaQuery({ query: "(max-width:650px)" })
  
    const size = {xs}

    return (
        <div>
            <Header />
            {!size.xs ? (
                <div>
                    <div class="container">

                        <div class="inner">
                            <img 
                                src={NoapteaMuzeelorPic}
                                height={400}
                                width={300}    
                                style={{
                                    width: '200%',
                                    height: '140%'
                                }}
                            />
                        </div>

                        <div class="outer">
                            <h2>Noaptea Muzeelor</h2>
                            <p>INCUBATOR13 își deschide ușa de Noaptea Muzeelor având un program divers pentru toate vârstele, combinând ateliere pentru copii și adulți, expoziții de sculptură în piatră și lemn reciclat, iar seara live session cu dj și artiști instrumentiști și nu numai. Veniți la INCUBATOR13 pentru o zi și o seară specială.  </p>
                        </div>
                    </div>
                    </div>
                    
                ) 






                :





                (
                    <div className="mobile-muzee">
                        <div  style={{
                                margin: 'auto',
                                width: '50%',
                                marginTop: '5rem',
                                marginBottom: '5rem '
                            }}>
                        <img 
                            src={NoapteaMuzeelorPic}
                            height={400}
                            width={300}
                        
                        />
                        </div>
                        <div style={{
                                margin: 'auto',
                                width: '80%',
                                marginBottom: '5rem '
                            }}
                            className="mobile-info"
                        >
                            <h2>Noaptea Muzeelor</h2>
                            <p>INCUBATOR13 își deschide ușa de Noaptea Muzeelor având un program divers pentru toate vârstele, combinând ateliere pentru copii și adulți, expoziții de sculptură în piatră și lemn reciclat, iar seara live session cu dj și artiști instrumentiști și nu numai. Veniți la INCUBATOR13 pentru o zi și o seară specială.  </p>
                        </div>
                    </div>
                )}

            <div class="container-cards">

                <div class="card">
                    <div class="face face1">
                    <div class="content">
                        <h2 class="java" style={{marginTop: '-90px'}}>MICUL GRAFICIAN</h2>
                        <p class="java"  style={{marginBottom: '40px', fontSize: '20px'}}>ANDREI SUCIU</p>
                        
                        <h2 class="java">IMPRO TIME</h2>
                        <p class="java" style={{fontSize: '20px'}}>RARES BUDILEANU</p>

                        <h3 style={{marginTop: '30px'}}>16:00 - 18:00</h3>
                    </div>
                    </div>
                    <div class="face face2" style={{fontSize: '40px', color: 'white'}}>
                        ATELIERE
                    </div>
                </div>

                <div class="card">
                    <div class="face face1">
                    <div class="content">
                        <h2 class="java" style={{marginTop: '-90px'}}>SUISEKI - ARTA JAPONEZA</h2>
                        <p class="java"  style={{marginBottom: '40px', fontSize: '20px'}}>TOTH CSABA</p>
                        
                        <h2 class="java">ARTA RECICLATA - RENASCUT DIN LEMN</h2>
                        <p class="java" style={{fontSize: '20px'}}>DEMETER JANOS</p>

                        <h3 style={{marginTop: '30px'}}>16:00 - 02:00</h3>
                    </div>
                    </div>
                    <div class="face face2" style={{fontSize: '40px', color: 'white'}}>
                        EXPOZITII
                    </div>
                </div>


                <div class="card">
                    <div class="face face1">
                    <div class="content">
                        <h2 class="java" style={{marginTop: '-90px'}}>JAMMING</h2>
                        <p class="java"  style={{marginBottom: '40px', fontSize: '20px'}}> MARIUSESCU, BRODY</p>

                        <h3 style={{marginTop: '30px'}}>22:00 - 02:00</h3>
                    </div>
                    </div>
                    <div class="face face2" style={{fontSize: '40px', color: 'white'}}>
                        LIVE SESSION
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>
    )
}

export default NoapteaMuzeelor;