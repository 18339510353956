import { Routes, Route, BrowserRouter } from "react-router-dom";

import Homepage from "./pages/Homepage/Homepage";

import SinglePageError from "./pages/Error/SinglePageError";
import Evenimente from "./pages/Evenimente/Evenimente";
import EvenimenteSinglePage from "./pages/EvenimenteSinglePage/EvenimenteSinglePage";
import Echipa from "./pages/Echipa/Echipa";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import Cv from "./pages/CV/CV";
import Proiect from "./pages/Proiect/Proiect";
import NoapteaMuzeelor from "./pages/NoapteaMuzeelor/NoapteaMuzeelor";



function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route index element={<Homepage />}/>
          <Route path="evenimente" element={<Evenimente/>} errorElement={<SinglePageError />}/>
          <Route path="/detalii-spectacol/:id" element={<EvenimenteSinglePage />}/>
          <Route path="/echipa" element={<Echipa />} />
          <Route path="/despre-noi" element={<AboutUs />}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/cv/:name" element={<Cv />} />
          <Route path="/proiect" element={<Proiect />} />
          <Route path="/noaptea-muzeelor" element={<NoapteaMuzeelor />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
