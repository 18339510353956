import { useRouteError } from 'react-router-dom';

const SinglePageError = () => {

  const error = useRouteError();

  return (
    <>
        <h2>there was an error...</h2>;
        <h3>{error}</h3>
    </>
    )
};

export default SinglePageError;
