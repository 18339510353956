import { Segment, Grid } from 'semantic-ui-react';

import ReviewCard from "../../../components/ReviewCard/ReviewCard"
import { primaryGrey } from '../../../utils/colors';

import { homepageReviews } from '../../../utils/dummyArray';

const HomepageReviews = () => {
    return (
        <Segment
            style={{
                backgroundColor: primaryGrey,
                padding: 0,
                margin: 'auto',
                border: 0,
                marginTop: '15rem',
                marginBottom: '15rem',
                boxShadow: 'none'
            }}
        >
            <Grid container columns={2} stackable>
                {homepageReviews.map((review, index) => (
                    <Grid.Column key={index}>
                        <ReviewCard data={review} />
                    </Grid.Column>
                ))}
            </Grid>
        </Segment>
    )
}

export default HomepageReviews;
