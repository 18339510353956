import { Card } from 'semantic-ui-react';
import { useState } from 'react';

import { 
    primaryGreen,
    primaryGrey,
    primaryRed,
    primaryYellow
} from '../../utils/colors';

import { 
    headingFontSize,
    headingFontSize2,
    mediumSize2
} from '../../utils/fonts';

const ReviewCard = ({ data }) => {
    const [likeHovered, setLikeHovered] = useState(false);

    console.log('like Hovered', likeHovered)
    const [starHovered, setStarHovered] = useState(false);

    return (
        <Card style={{ width: '100%', minHeight: '15rem' }}>
            <div className="content" style={{ backgroundColor: primaryGreen}}>
                <i 
                    className="right floated like icon" 
                    style={{ 
                        backgroundColor: primaryGreen, 
                        fontSize: headingFontSize2, 
                        color: likeHovered ? primaryRed : primaryGrey,
                    }}
                    onMouseEnter={() => setLikeHovered(true)}
                    onMouseLeave={() => setLikeHovered(false)}
                ></i>
                <i 
                    className="right floated star icon" 
                    style={{ 
                        backgroundColor: primaryGreen, 
                        fontSize: headingFontSize2, 
                        color: starHovered ? primaryYellow : primaryGrey,
                    }}
                    onMouseEnter={() => setStarHovered(true)}
                    onMouseLeave={() => setStarHovered(false)}
                ></i>
                <div className="header" style={{ backgroundColor: primaryGreen, color: primaryGrey, fontSize: headingFontSize, borderBottom: `2px solid ${primaryGrey}`}}>{data.name}</div>
                <div className="description" style={{ backgroundColor: primaryGreen}}>
                    <p style={{ backgroundColor: primaryGreen, color: primaryGrey, fontSize: mediumSize2}}>{data.review}</p>
                </div>
            </div>

            <div className="extra content" style={{ backgroundColor: primaryGreen}}>
                <span className="left floated like" style={{ backgroundColor: primaryGreen, fontSize: headingFontSize2, color: primaryGrey}}>
                    <i className="like icon" style={{ backgroundColor: primaryGreen, color: primaryRed}}></i>
                    {data.likes}
                </span>
                <span className="right floated star" style={{ backgroundColor: primaryGreen, fontSize: headingFontSize2, color: primaryGrey}}>
                    <i className="star icon" style={{ backgroundColor: primaryGreen, color: primaryYellow, fontSize: headingFontSize2}}></i>
                    {data.fav}
                </span>
            </div>
        </Card>
    );
}

export default ReviewCard;
