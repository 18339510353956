import { useState, useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';

import { 
    Image, 
    Menu, 
} from 'semantic-ui-react';

import HeaderLg from './HeaderLg'
import HeaderMb from './HeaderMb'

import Logo from '../../utils/GeneralImages/Logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { primaryGreen } from '../../utils/colors';
import { useNavigate, useLocation } from 'react-router-dom';

const HeaderComponent = () => {
   

    const navigate = useNavigate();
    const location = useLocation();


    const [activeItem,setactiveItem]=useState("acasa")
    
    
    const handleItemClick = (e, { name, id }) => {
        setactiveItem(name)
        setactiveItem(name);
        if (name === "acasa" || name === "logo") {
            navigate("/");
        } else {
            navigate(`/${name}`);
        }
    }

    const handleFacebookClick = (e, { name }) =>{
        window.location.href = 'https://www.facebook.com/incubator13treishpe';
    }

    const handleInstagramClick = (e, { name }) =>{
        window.location.href = 'https://www.instagram.com/incubator.13/';
    }
    
    const none =useMediaQuery({ query: "(max-width:576px)" }) 
    const sm = useMediaQuery({ query: "(min-width:576px)" })
    const md = useMediaQuery({ query: "(min-width:1000px)" })
    const xl = useMediaQuery({ query: "(min-width:1200px)" })
    const xxl = useMediaQuery({ query: "(min-width:1400px)" })
  
    const size = {none,sm,md,xl,xxl}
   
    const isMobile = md || sm || none; 

  const renderLinks=()=>{
    return <>
        <Menu.Item
            name='acasa'
            id='acasa'
            active={activeItem === 'logo'}
            onClick={handleItemClick} 
            style={{
                marginRight: xxl? '24%' : xl ? '20%' : '9%'
            }}
        >
            <Image src={Logo} alt="Logo" size="small" />
        </Menu.Item>


        <Menu.Item
            name='acasa'
            id='acasa'
            active={activeItem === 'acasa'}
            onClick={handleItemClick}
            position={isMobile ? undefined : "right"}
        />
        <Menu.Item
            name='proiect'
            id='proiect'
            active={activeItem === 'proiect'}
            onClick={handleItemClick}
        />
        <Menu.Item
            name='evenimente'
            id="evenimente"
            active={activeItem === 'evenimente'}
            onClick={handleItemClick}
        />
        <Menu.Item
            name='noaptea-muzeelor'
            id="noaptea-muzeelor"
            active={activeItem === 'noaptea-muzeelor'}
            onClick={handleItemClick}
        />
        <Menu.Item
            name='echipa'
            id="echipa"
            active={activeItem === 'echipa'}
            onClick={handleItemClick}
        />
        <Menu.Item
            name='despre-noi'
            id="despre-noi"
            active={activeItem === 'despre-noi'}
            onClick={handleItemClick}
        />
        <Menu.Item
            name='contact'
            id="contact"
            active={activeItem === 'contact'}
            onClick={handleItemClick}
        />


        {size.md && (
            <Menu.Item
                className="social-links"
                position='right'
            >
                <Menu.Item onClick={handleFacebookClick} style={{ padding: '0 5px' }}>
                    <FontAwesomeIcon icon={faFacebookF} size="2xl" style={{ backgroundColor: primaryGreen }} />
                </Menu.Item>

                <Menu.Item onClick={handleInstagramClick} style={{ padding: '0 5px' }}>
                    <FontAwesomeIcon icon={faInstagram} size="2xl" style={{ backgroundColor: primaryGreen }} />
                </Menu.Item>
            </Menu.Item>
        )}
    </>
  }

  

  useEffect(() => {
    const path = location.pathname.substring(1);
    setactiveItem( path || "acasa" ); 
}, [location.pathname]);


  return (
    <div className='header-container'>
        {size.md ? 
            <HeaderLg 
                renderLinks={renderLinks} 
            /> 
            : 
            <HeaderMb 
                renderLinks={renderLinks}
            /> 
        }
    </div>
  )
}

export default HeaderComponent;


const styleButtonSend = {
    fontSize:16,
}