import React from "react";

import NoapteaMuzeelor from '../../utils/images/NoapteaMuzeelor.jpg'

import './HomepageMuzeu.css'

const HompeageMuzeu = () => {
    return (
        <segment>
            <div class="minip">

                <div class="mg">
                    <div class="clr"></div>
                    <div class="group">
                    <span>INCUBATOR 13</span>
                    </div>
                </div>

                <div class="av" style={{backgroundImage: `url(${NoapteaMuzeelor})`}}></div>
                
                <div class="info">
                    <name>18 MAI</name>
                    <deets>
                    Turnul portii<br/>
                    Cetatea Medievala
                    </deets>
                </div>

                <a class="plot" title="plot with jinkyu" href="/noaptea-muzeelor">
                    Detalii →
                </a>
                </div>
        </segment>
    )
}


export default HompeageMuzeu;