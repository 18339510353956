import PersonCard from '../../components/PersonCard/PersonCard';


import { useMediaQuery } from 'react-responsive';

import { primaryGrey } from '../../utils/colors';
import HeaderComponent from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import { team, colaboratori } from '../../utils/dummyArray';

import './Echipa.css';


const Echipa = () => {

    const xxl = useMediaQuery({ query: "(max-width:900px)" })

    return (
        <div style={{textAlign: 'center'}}>
            <HeaderComponent />
            <p className='title'>Echipa noastra</p>
            <div
                style={{
                    backgroundColor: primaryGrey,
                    display: xxl? '' : 'flex',
                    width: '100%',
                    margin: 'auto',
                    marginBottom: '2rem',
                    marginTop: '5rem'
                }}
            > 
                <div
                    className="cards-container"
                    style={{
                        backgroundColor: primaryGrey,
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                        width: '90%',
                        margin: 'auto',
                        marginBottom: '2rem',
                    }}
                >
                    <PersonCard data={team} />
                </div>
            </div>

            <p className='title'>Colaboratori KaBoom</p>
            <div
                style={{
                    backgroundColor: primaryGrey,
                    display: xxl? '' : 'flex',
                    width: '100%',
                    margin: 'auto',
                    marginBottom: '2rem',
                    marginTop: '10rem'
                }}
            > 
            
               
                <div
                    className="cards-container"
                    style={{
                        backgroundColor: primaryGrey,
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly',
                        width: '90%',
                        margin: 'auto',
                        marginBottom: '2rem',
                    }}
                >
                    <PersonCard data={colaboratori} />
                </div>
            </div>

            <Footer />

        </div>
    )
}

export default Echipa;