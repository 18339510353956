import Footer from '../../components/Footer/Footer';
import HeaderComponent from '../../components/Header/Header';

import { primaryGrey } from '../../utils/colors';

import './AboutUs.css';

import aboutUs from '../../utils/GeneralImages/aboutus.png'
import despreNoi from '../../utils/GeneralImages/desprenoi.jpg';

const AboutUs = () => {
    return (
        <div style={{ backgroundColor: primaryGrey}}>
            <HeaderComponent />

            <div className="about-us-container">

                <div className="image-section">
                    <img src={despreNoi} alt="about us"/>
                </div>

                <div className="info-section">
                    <p>INCUBATOR 13 e munca activă a unor artiști/căutători care își
                        doresc să țină comunitatea din Târgu-Mureș conectată la cultura
                        alternativă. Ideea de Incubator 13 s-a născut în 2012, în cadrul
                        proiectului Practică Teatrală în Regiunile de Centru şi Nord-Vest
                        Tg. Mureș, unde Rareș Budileanu a regizat, în premieră, textul
                        "Pisica Verde" de Elise Wilk, câștigând premiul 1 pentru cel mai
                        bun spectacol. Din octombrie 2018, INCUBATOR 13 devine asociație
                        cu drepturi depline și începe o serie de proiecte. Cel mai
                        important dintre ele - spectacolul “Săraci" de Lucy Thurber - în
                        regia lui Rareș Budileanu, în co-producție cu Teatrul Național Tg.
                        Mureș, a fost montat pentru prima dată în noiembrie 2019. Din mai
                        2022, odată cu începerea activității în Turnul Porții din Cetatea
                        Medievală Târgu-Mureș, INCUBATOR 13 funcționează ca spațiu de
                        colaborare și intersecție a numeroase tipuri de artă.
                    </p>
                </div>
            </div>

            <p className='left-p'>
                    Pornind din dorința de a susține acte artistice “pretimpurii”, de a
                crea vizibilitate pentru performance-uri underground și de a naște
                colaborări cât mai diverse, din 2022 și peste 80 de artiști am pus în mișcare evenimentele a
                peste 50 de artiști locali & naționali: expoziții de pictură, desen,
                artă digitală, spectacolul de improvizație “KaBoom Unlimited Impro”,
                spectacole-lectură, concerte alternative, ateliere de artă  ExplozivART - proiect de educație nonformală. Și cum
                hărnicia s-a născut în târguri în care se mai pun mâini lângă mâini și
                se mai bat cuie și-n pereții care cad sau se ridică - chiar și-atunci
                când nu sunt ai tăi - în ultimul an am colaborat cu o serie de
                proiecte ale Târgului: Zacusca de Artă 2022 & 2023, Băla Fest 2023
                (prima ediție pe post de co-organizatori la un mult-îndrăgit festival
                boutique), Festivalul Luminii 2023, Școala de Primăvară “Nativ
                Creativ”. Ne dorim să atragem cât mai mulți artiști, oferindu-le un
                spațiu unde să își exprime lucrurile care le sunt importante.
                Incubatorul e deschis oricărui fel de căutări și întâmplări artistice.
                Așteptăm și căutăm activ noi propuneri.
            </p>
            
            <Footer/>
        </div>
    );
}

export default AboutUs;
