import React from "react";

import HeaderComponent from "../../components/Header/Header";

import { atelierDetalii } from "../../utils/dummyArray";


import './Proiect.css';
import Footer from "../../components/Footer/Footer";

const Proiect = () => {
    return (
        <div >
            <HeaderComponent />

            
            <div className="main-container-content">
                <h2 className='incub'>INCUBATOR13 <span className='text-are'>are un nou spectacol.</span></h2>
                <h1 className='elise'>Exploziv 
                    <span>de</span> 
                    <span className='elise-2'>Elise Wilk</span>
                </h1>
                <h2 className='incub-2'>Exploziv – un spectacol cu și despre liceeni. Este un spectacol despre relația dintre ei, părinți și profesori, despre comunicare (sau mai degrabă lipsa ei) și despre dinamica acestor grupuri</h2>
                <h2 className="incub-2">"Exploziv" este un spectacol care ar trebui văzut de toate aceste categorii și nu numai. </h2>
                <h2 className="incub-2">Super bonus: veți descoperi niște tineri absolut minunați!!!</h2>
            </div>

            {/* <div className="program">
                <div className="program-split">
                    <div className="program-item">
                        <h3>Turnul Portii INCUBATOR13</h3>
                        <div class="month">      
                            <ul>
                                <li>
                                Decembrie<br/>
                                <span style={{fontSize: '18px', backgroundColor: '#79826b'}}>2023</span>
                                </li>
                            </ul>
                        </div>

                            <ul class="weekdays">
                                <li>Mo</li>
                                <li>Tu</li>
                                <li>We</li>
                                <li>Th</li>
                                <li>Fr</li>
                                <li>Sa</li>
                                <li>Su</li>
                            </ul>

                            <ul class="days">  
                                <li>1</li>
                                <li>2</li>
                                <li><span class="active">3</span></li>
                                <li>4</li>
                                <li><span class="active">5</span></li>
                                <li>6</li>
                                <li>7</li>
                                <li>8</li>
                                <li>9</li>
                                <li>10</li>
                                <li>11</li>
                                <li>12</li>
                                <li>13</li>
                                <li>14</li>
                                <li>15</li>
                                <li><span class="active">16</span></li>
                                <li>17</li>
                                <li>18</li>
                                <li>19</li>
                                <li>20</li>
                                <li>21</li>
                                <li>22</li>
                                <li>23</li>
                                <li>24</li>
                                <li>25</li>
                                <li>26</li>
                                <li>27</li>
                                <li>28</li>
                                <li>29</li>
                                <li>30</li>
                                <li>31</li>
                            </ul>
                        </div>
                        <div className="program-item">
                            <h3>Teatrul Ariel</h3>
                            <div class="month">      
                                <ul>
                                    <li>
                                    Decembrie<br/>
                                    <span style={{fontSize: '18px', backgroundColor: '#79826b'}}>2023</span>
                                    </li>
                                </ul>
                            </div>

                                <ul class="weekdays">
                                    <li>Mo</li>
                                    <li>Tu</li>
                                    <li>We</li>
                                    <li>Th</li>
                                    <li>Fr</li>
                                    <li>Sa</li>
                                    <li>Su</li>
                                </ul>

                                <ul class="days">  
                                    <li>1</li>
                                    <li>2</li>
                                    <li>3</li>
                                    <li>4</li>
                                    <li>5</li>
                                    <li>6</li>
                                    <li>7</li>
                                    <li><span class="active">8</span></li>
                                    <li><span class="active">9</span></li>
                                    <li><span class="active">10</span></li>
                                    <li>11</li>
                                    <li>12</li>
                                    <li>13</li>
                                    <li>14</li>
                                    <li>15</li>
                                    <li>16</li>
                                    <li>17</li>
                                    <li>18</li>
                                    <li>19</li>
                                    <li>20</li>
                                    <li>21</li>
                                    <li>22</li>
                                    <li>23</li>
                                    <li>24</li>
                                    <li>25</li>
                                    <li>26</li>
                                    <li>27</li>
                                    <li>28</li>
                                    <li>29</li>
                                    <li>30</li>
                                    <li>31</li>
                                </ul>
                        </div>
                </div>
                
                </div>*/}
                {/* <h3>Ora de incepere: 18:00. Intrarea libera</h3> */}
                <div> 
                <h3
                    style={{
                        fontSize: '24px',
                        marginTop: '8rem'
                    }}
                >Trainerii nostri</h3>

                {atelierDetalii && Object.values(atelierDetalii).map((item, index) => (
                    <div className="container-atelier-left">
                        <div className="image-container-atelier-left">
                        <img src={item.img} alt="" />
                        </div>
                        <div className="information-container-atelier-left">
                        <h3>{item.title}</h3>
                        <h5>{item.descriere}</h5>
                        {item.name !== "" ? (
                            <a href={`/cv/${item.name}`} className="link-cv">
                            Spre CV coordonator
                            </a>
                        ) : null}
                        </div>
                  </div>
                ))}

</div>
           

            <h3
                style={{
                    fontSize: '24px',
                    marginTop: '8rem'
                }}
            >
                Recenzii
            </h3>

            <div className="testimonials-container">
                <div className="post">
                    <div className="post-header">
                        <span className="post-title">Adriana Tomulețiu</span>
                    </div>
                    <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                    {review1}
                    </div>
                </div>
            </div>


            <div className="testimonials-container">
                <div className="post">
                    <div className="post-header">
                        <span className="post-title">Codruța Pop</span>
                    </div>
                    <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                    {review2}
                    </div>
                </div>
            </div>


            <div className="testimonials-container" style={{marginBottom:'5rem'}}>
                <div className="post">
                    <div className="post-header">
                        <span className="post-title">Mădălina Hondrea</span>
                    </div>
                    <div className="post-content" style={{whiteSpace: 'pre-line'}}>
                    {review3}
                    </div>
                </div>
            </div>

            <div
            style={{
                width: '80%',
                margin: 'auto',
                fontSize: '18px',
                color: '#79826b',
                marginBottom: '4rem'
            }}
            >
                Proiect co-finanțat de Municipiul Târgu Mureş în sesiunea 2023.
                Proiectul nu reprezintă în mod necesar poziția Municipiului Târgu Mureş şi a Consiliului local care nu sunt responsabili de conţinutul proiectului sau de modul în care rezultatele acestuia pot fi folosite. Acestea sunt în întregime responsabilitatea beneficiarului finanţării”

            </div>

            <Footer />
        </div>

    )
}


export default Proiect;


const review1 = `Acest spectacol este unul cu adevărat ”exploziv”, care te zdruncină ca părinte. Te zdruncină atât de puternic, încât te trezește la realitate, îndemnându-te să te uiți în ”oglindă”, să te autoanalizezi: ”Ce fel de părinte sunt? Care sunt nevoile mele? Care este sensul meu? Care este atitudinea mea față de cei din jur, față de copiii mei? Oare sunt suficient de atent(ă) la nevoile lor? La trăirile lor? Sunt lângă ei atunci când au nevoie de mine? Sunt prezent(ă)?” 
Pentru că tot ceea ce suntem noi, ca adulți, ca părinți, toate trăirile, nevoile, traumele noastre, toate reacțiile și atitudinile noastre, își pun amprenta asupra copiilor noștri, asupra relației pe care o avem cu ei, uneori cu consecințe nefaste.

Este un spectacol ”exploziv”, care te zdruncină pe tine ca profesor și te îndeamnă să te interoghezi: Cum sunt eu ca profesor? Cum mă văd elevii mei? Oare cine sunt ei cu adevărat? Oare care sunt adevăratele lor nevoi? Ce trecut au, ce experiențe anterioare? Ce le place? Ce nu le place? Oare timpul petrecut aici, cu mine, este un timp de calitate pentru ei? Ceea ce i-am învățat le folosește cu adevărat în viață? Oare se simt în siguranță la școală? Și multe altele... 
Pentru că el, profesorul, nu este doar un transmițător de cunoștințe, un formator de competențe și abilități la elevi; e important să fie mai mult decât atât: să fie aproape de elevi, să-i ajute să înțeleagă valoarea educației, să-i facă să iubească școală, să le dezvolte încredere în ei, în resursele lor interioare, să le cultive optimismul, nu frica, starea de bine, nu stresul, să-i facă se simtă prețuiți pentru ceea ce sunt, exact așa cum sunt. El profesorul, nu lucrează cu materiale de construcții, unde poate se mai permit anumite rebuturi, care se pot remedia. 
Lucrează cu suflete, cu ființe umane, și un suflet odată distrus, este greu de recuperat.

Este un spectacol ”exploziv” pentru adolescenți; este ca un strigăt de ajutor: ”Sunt aici! Uita-te la mine! Mă vezi? Nu știi nimic despre mine!” Un strigăt de ajutor îndreptat spre părinți, spre profesori, spre colegi, spre societate în general. Este un strigăt care are rostul de a te trezi din indiferență, din nepăsare, din ”adormire”, care te îndeamnă să fii prezent, aici și acum, și să construiești, înainte de a fi prea târziu, o lume mai frumoasă, o lume mai bună.

Acest spectacol îl simt ca pe o lecție din care toți avem de învățat – părinți, profesori, elevi. O lecție pe care ne-au oferit-o 17 adolescenți minunați care, cu siguranță, și-au dedicat multe ore din timpul lor pentru a o pune în scenă! 

Este o lecție atât de bine scrisă de Elise Wilk, care reflectă probleme ale adolescenților, de la conflictele lor interioare până la conflictele cu cei din jur, nevoia lor de a ieși în evidență, de fi văzuți, ascultați, valorizați, nevoia de suport parental și psiho-educațional. Este o lecție despre viață, despre dorințe neîmplinite, despre drame umane, care te îndeamnă la reflecție. 

Este o lecție pe care cei 17 adolescenți au învățat-o alături de niște profesori minunați – actorii Anca Loghin și Rareș Budileanu – a căror implicare este, la rândul ei, o lecție pentru mulți dintre noi. Ați reușit prin acest proiect să ne arătați cât de valoroși sunt adolescenții, că interiorul lor e plin de resurse, iar dacă le scoți la iveală, le cultivi, le potențezi, creezi miracole. Și da, spectacolul ”Exploziv” este un miracol. 

Felicitări!`;


const review2 = `Viața netrăită a adulților este o grea povară aruncată pe umerii copiilor. Toate acele lucruri dosite în sertarele sufletului nostru, doruri, neîmpliniri, frustrări, dorințe înăbușite se revarsă grav tocmai asupra celor pentru care pretindem ca “ne sacrificăm”.

Piesa “Exploziv”, în regia lui Rareș Budileanu este, din punctul meu de vedere, un manifest! Exprimat prin teatru, pune pe tapet cele mai mari provocări ale adolescentului din generația “selfie" , “tik tok “ și “Nu am timp! Vorbim mâine” : uciderea creativității, sinuciderea, bullyng-ul, drogurile, indiferența, criza de identitate, dorința de apartenență și drama generată de lipsa ei.

O piesă care trebuie văzută de părinți și profesori în egală măsură. O piesă care intrigă, care schimbă, care trezește. Care ne arată ca singurele modele adevărate ale copiilor noștri suntem noi, adulții din jurul lor, care conducem prin puterea exemplului și mai puțin a cuvântului. 

Felicitări Rares Budileanu! Felicitări Anca Loghin! Felicitări adolescenților care au transmis în mai puțin de două ore ceea ce ne prefacem ca nu vedem de ani de zile!`;


const review3 = `Un spectacol foarte interesant, excelent jucat de copiii din proiect! S-a (intre)vazut munca din spatele lui, atât a protagoniștilor, cât și a celor care i-au îndrumat. Tema piesei este în mod cert de actualitate și ne amintește cât sunt de fragili și deopotrivă puternici adolescenții. Felicitări pentru proiect, Rares Budileanu și Anca Loghin!`;
