
export const primaryGreen = '#79826b';
export const secondaryGreen = '#757b63';
export const tertiaryGreen = '#6c7c63';

export const primaryGrey = '#e3e2dd';
export const secondaryGrey = '#aea4a4';

export const primaryBrown = '#923b16';

export const beige = '#c2b7ab';

export const darkRed = '#e60000';

export const textColor = '#21201b';

export const primaryRed = ' #ff0000';

export const primaryYellow = ' #ffff00'