import { useNavigate } from 'react-router-dom';

import './EventCard.css';

const EventCard = ({ data }) => {

    const navigate = useNavigate();

    return (
        data ? Object.values(data).reverse().map((item, index) => (
            <div className="card-hover" key={index}>
                <div className="card-hover__content">

                    <h3 className="card-hover__title" style={{color: '#e3e2dd'}}>
                        {item.title}
                    </h3>

                    <p className="card-hover__text" style={{color: '#e3e2dd'}}>{item.address}</p>
                    <p className="card-hover__text" style={{color: '#e3e2dd'}}>{item.hour}</p>
                    <p className="card-hover__text" style={{color: '#e3e2dd'}}>{item.date} <span className='span-hover'>{item.month}</span></p>

                    <a href="#" className="card-hover__link">
                        <span 
                            className='learn' 
                            style={{color: '#e3e2dd', marginBottom: '-40px'}}   
                            onClick={() => { navigate(`/detalii-spectacol/${item.id}`)}}
                        >
                            Detalii
                        </span>

                        <svg style={{color: '#e3e2dd', marginBottom: '-40px'}} fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                        </svg> 

                    </a>
                </div>

                <img src={item.src} alt={item.title} />
                
            </div>
        )) : null
    );
};

export default EventCard;
