
export const primaryFontFamily = "'Crimson Pro', serif";

export const primaryFontFamilyBold = "'Crimson Pro Bold', serif";

export const smallFontSize = '12px';

export const baseFontSize = '16px';
export const baseFontSize2 = '14px';

export const mediumSize = '22px';
export const mediumSize2 = '18px';
export const mediumSize3 = '16px';
export const mediumSize4 = '18px';
export const mediumSize5 = '16px';

export const headingFontSize = '24px';
export const headingFontSize2 = '20px';
export const headingFontSize3 = '18px';
export const headingFontSize4 = '20px';
export const headingFontSize5 = '17px';

export const iconsSize = '25px';

