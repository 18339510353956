import { 
    Menu, 
    Segment, 
} from 'semantic-ui-react';

import { primaryGreen } from '../../utils/colors';

const HeaderLg = ({renderLinks}) => {

    return (
        <Segment
            size='mini'
            style={{
                backgroundColor: primaryGreen,
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                borderRadius: 0,
            }}
        >
            
            <Menu inverted secondary
                style={{
                    height: 'fit-content',
                    width: '100%'
                }}
            >
                {renderLinks()}
            </Menu>

        </Segment>
    )
}

export default HeaderLg;