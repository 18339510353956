import React from "react";

import { useMediaQuery } from 'react-responsive';

import { Card } from 'semantic-ui-react';

import { currentShows } from "../../utils/dummyArray";

import { useNavigate } from "react-router-dom";

import { 
    textColor, 
    primaryGreen 
} from "../../utils/colors";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLocation } from "@fortawesome/free-solid-svg-icons";

import { 
    mediumSize, 
    mediumSize2,
    primaryFontFamily, 
    baseFontSize,
    baseFontSize2, 
    headingFontSize,
    headingFontSize2,
    mediumSize3,
    headingFontSize3,
    mediumSize4,
    mediumSize5,
    headingFontSize4,
    headingFontSize5,
    smallFontSize
} from "../../utils/fonts";

import './EventTicket.css';


const EventTicket = () => {

    const navigate = useNavigate();

    const xxs =useMediaQuery({ query: "(max-width:410px)" }) 
    const xs =useMediaQuery({ query: "(max-width:560px)" }) 
    const s =useMediaQuery({ query: "(max-width:650px)" }) 
    const sm = useMediaQuery({ query: "(max-width:800px)" })
    const md = useMediaQuery({ query: "(max-width:950px)" })
    const xl = useMediaQuery({ query: "(min-width:1200px)" })
    const xxl = useMediaQuery({ query: "(max-width:1470px)" })


    return(
        <>
            {currentShows.map((item, index) => (
                <Card 
                    fluid 
                    style={{ 
                        width: sm ? '100%' : md ? '80%' : '70%',
                        margin: 'auto', 
                        marginBottom: '4rem', 
                        backgroundColor: 'white',
                        boxShadow: `10px 10px 10px ${primaryGreen}`,
                        height: xxs ? '10%' : '25%'
                    }} 
                    onClick={() => navigate(`/detalii-spectacol/${item.id}`)}
                    key={index}
                >
                    <div 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center',
                            height: '200px', 
                            fontSize: mediumSize,
                        }}
                    >
                        {xs ? null : 
                        <div 
                            style={{ 
                                flex: '30%', 
                                height: '100%', 
                                backgroundImage: `url(${item.src})`, 
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        ></div>}
                        

                        <div 
                            style={{ 
                                flex: '55%', 
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'white',
                                borderRight: xl ? '2px dotted black' : null
                            }}
                        >

                            <div 
                                style={{ 
                                    width: '90%', 
                                    height: '90%',
                                    backgroundColor: 'white',
                                }}
                            >
                                <div 
                                    style={{
                                        display: 'flex',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.5rem',
                                        fontFamily: primaryFontFamily,
                                        fontSize: baseFontSize,
                                        borderTop: `2px solid ${primaryGreen} `, 
                                        borderBottom: `2px solid ${primaryGreen} `,
                                        backgroundColor: 'white' 
                                        
                                    }}
                                >
                                   
                                            <div
                                                style={{
                                                    flex: '33%',
                                                    textAlign: 'left',
                                                    color: textColor,
                                                    fontFamily: primaryFontFamily,
                                                    fontSize: xl ? baseFontSize2 : s ? smallFontSize : baseFontSize,
                                                    fontWeight: 'bold',
                                                    backgroundColor: 'white' 
                                                }}
                                            >
                                                {item.day}
                                            </div>
                                            <div
                                                style={{
                                                    flex: '33%',
                                                    alignItems: 'center',
                                                    display: 'flex', 
                                                    justifyContent: 'center',
                                                    color: primaryGreen,
                                                    fontSize: xl ? mediumSize2 : s ? mediumSize3 : mediumSize,
                                                    fontWeight: 'bold',
                                                    backgroundColor: 'white' 
                                                }}
                                            >
                                                {item.date}
                                            </div>
                                            <div
                                                style={{
                                                    flex: '33%',
                                                    alignItems: 'end',
                                                    textAlign: 'right',
                                                    color: textColor,
                                                    fontWeight: 'bold',
                                                    fontSize: xl ? baseFontSize2 : s ? smallFontSize : baseFontSize,
                                                    backgroundColor: 'white' 
                                                }}
                                            >
                                                {item.year}
                                            </div>

                                </div>


                                <div 
                                    style={{ 
                                        textAlign: 'center',
                                        backgroundColor: 'white' 
                                    }}
                                >
                                    <p
                                        style={{
                                            fontFamily: primaryFontFamily,
                                            fontSize: xs ? mediumSize2 : s ? mediumSize5 : md ? mediumSize4 : xl ? mediumSize3 : xxl ? mediumSize2 : mediumSize,
                                            color: textColor,
                                            fontWeight: 'bold',
                                            margin: 0,
                                            backgroundColor: 'white' 
                                        }}>{item.title}</p>

                                    <p className="subtitle"
                                        style={{
                                            fontFamily: primaryFontFamily,
                                            fontSize: xs ? headingFontSize2 :s ? headingFontSize5 : md ? headingFontSize4 : xl ? headingFontSize3 : xxl ? headingFontSize2 : headingFontSize,
                                            color: primaryGreen,
                                            fontWeight: 'bold',
                                            backgroundColor: 'white' 
                                        }}
                                    >{item.subtitle}</p>

                                    <p
                                        style={{
                                            fontFamily: primaryFontFamily,
                                            fontSize: xs ? mediumSize2 : s ? mediumSize5 : md ? mediumSize4 : xl ? mediumSize3 : xxl ? mediumSize2 : mediumSize,
                                            color: textColor,
                                            fontWeight: 'bold',
                                            marginTop: '-10px',
                                            backgroundColor: 'white' 
                                        }}
                                    >{item.time}</p>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        paddingTop: '0.5rem',
                                        paddingBottom: '0.5rem',
                                        fontFamily: primaryFontFamily,
                                        fontSize: baseFontSize,
                                        borderTop: `2px solid ${primaryGreen} `,
                                        backgroundColor: 'white' 
                                    }}
                                >
                                    <div
                                                style={{
                                                    flex: '33%',
                                                    textAlign: 'left',
                                                    color: textColor,
                                                    fontFamily: primaryFontFamily,
                                                    fontSize: xl ? baseFontSize2 : s ? smallFontSize : baseFontSize,
                                                    fontWeight: 'bold',
                                                    backgroundColor: 'white' 
                                                }}
                                            >
                                                {item.address1}
                                            </div>
                                            <div
                                                style={{
                                                    flex: '33%',
                                                    alignItems: 'center',
                                                    display: 'flex', 
                                                    justifyContent: 'center',
                                                    color: primaryGreen,
                                                    fontSize: mediumSize,
                                                    fontWeight: 'bold',
                                                    backgroundColor: 'white' 
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faLocation} />
                                            </div>
                                            <div
                                                style={{
                                                    flex: '33%',
                                                    alignItems: 'end',
                                                    textAlign: 'right',
                                                    color: textColor,
                                                    fontWeight: 'bold',
                                                    fontSize: xl ? baseFontSize2 : s ? smallFontSize : baseFontSize,
                                                    backgroundColor: 'white' 
                                                }}
                                            >
                                                {item.address2}
                                            </div>

                                </div>
                                
                            </div>

                            
                        </div>
                        {xl ? <div 
                            style={{ 
                                flex: '20%', 
                                height: '100%', 
                                padding: '10px',
                                backgroundColor: 'white'
                            }}
                        >
                            <img src={item.qr} alt="qr" height={180} width={160}/>
                        </div> : null }
                        
                        </div>
                </Card>
            ))}
        </>
    )
}


export default EventTicket;
