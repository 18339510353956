import React from 'react';
import { Grid, Image, Segment, Icon } from 'semantic-ui-react';
import Logo from '../../utils/GeneralImages/Logo.png';
import './Footer.css'; 

import { primaryGreen, primaryGrey } from '../../utils/colors';

import { 
    headingFontSize2,
    mediumSize2
} from '../../utils/fonts';

const Footer = () => {
  return (
        <Grid columns={3} className="footer-grid" style={{margin: 'auto', backgroundColor: primaryGreen, paddingBottom: '1rem'}}>
            <Grid.Column style={{ backgroundColor: primaryGreen}}>
                <Image src={Logo} alt="Logo" size="small" style={{marginLeft: '2rem'}} />
            </Grid.Column>

            <Grid.Column style={{ backgroundColor: primaryGreen}}>
                <Segment.Group className="footer-segment-group" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none'}}>
                <Segment className="footer-segment" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none', height: '1rem', fontSize: headingFontSize2, color: primaryGrey}}>ADRESA</Segment>
                <Segment className="footer-segment" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none', height: '1rem', fontSize: mediumSize2, color: primaryGrey}}>Strada Avram Iancu, Bastionul Portii</Segment>
                <Segment className="footer-segment" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none', height: '3rem', fontSize: mediumSize2, color: primaryGrey}}>Cetatea Medievala Targu Mures</Segment>
                <Segment className="footer-segment" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none', height: '1rem', fontSize: headingFontSize2, color: primaryGrey}}>TELEFON: +40 723 314 899</Segment>
                </Segment.Group>
            </Grid.Column>

            <Grid.Column style={{ backgroundColor: primaryGreen}}>
                <Segment.Group className="footer-segment-group" style={{border: 0, boxShadow: 'none'}}>
                <Segment className="footer-segment" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none', height: '4rem', fontSize: headingFontSize2, color: primaryGrey}}>Ne poti gasi pe urmatoarele retele de socializare:</Segment>
                <Segment className="footer-icon-segment" style={{ backgroundColor: primaryGreen, border: 0, boxShadow: 'none', height: '1rem'}}>
                    <div className="footer-icon-container" style={{border: 0, boxShadow: 'none', marginRight: '45%', backgroundColor: primaryGreen}}>
                    <Icon name='facebook' size='big' style={{backgroundColor: primaryGreen, cursor: 'pointer'}}
                        onClick={() =>window.location.href = 'https://www.facebook.com/incubator13treishpe'}
                    />
                    <Icon name='instagram' size='big' style={{backgroundColor: primaryGreen, cursor: 'pointer'}}
                        onClick={() =>window.location.href = 'https://www.instagram.com/incubator.13/'}
                    />
                    </div>
                </Segment>
                </Segment.Group>
            </Grid.Column>
        </Grid>
  );
};

export default Footer;
