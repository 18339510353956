import { useState } from 'react';

import { 
    Menu, 
    Sidebar,
    Segment
} from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

import Logo from '../../utils/GeneralImages/Logo.png'

import { primaryGreen } from '../../utils/colors';
import { iconsSize } from '../../utils/fonts';


const Overlay = () => {
    return (
        <div style={{
            position: "fixed",
            height: "110vh",
            width: "100%",
            }} 
        />
    )
}

const HeaderMb = ({renderLinks}) => {

    const [visible, setVisible] = useState(false)
    const [icon, setIcon] = useState(<FontAwesomeIcon icon={faBars} style={{fontSize: iconsSize, backgroundColor: primaryGreen}}/>)
    const [ iconRadius, setIconRadius ] = useState("bars");

    const hideSidebar = () => {
        setIcon(<FontAwesomeIcon icon={faBars} style={{fontSize: iconsSize, backgroundColor: primaryGreen}}/>)
        setVisible(false);
        setIconRadius('bars');
    }

    const showSidebar = () => {
        setIcon(<FontAwesomeIcon icon={faXmark} style={{fontSize: iconsSize, backgroundColor: 'transparent'}}/>)
        setVisible(true)
        setIconRadius('mark');
    }

    const toggleSidebar = () => {
        visible ? hideSidebar() : showSidebar()
    }

    return (
        < Segment 
            style={{
                backgroundColor: primaryGreen,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: 0,
            }}
        >
            {visible && <Overlay />}

            <img 
                src={Logo} 
                alt="logo" 
                style={{    
                    width: '150px', 
                    height: '71px', 
                    backgroundColor: 'transparent'
                }}
            />

            <Menu
                size="tiny"
                borderless
                attached
                style={{ backgroundColor: 'transparent', border: 'none', width: '100px'}}
            >
                <Menu.Menu position='right'>
                    <Menu.Item 
                        onClick={toggleSidebar} 
                        style={{ 
                            backgroundColor: primaryGreen,
                            borderRadius: iconRadius === 'bars' ? '0' : '43px'
                        }}
                    >
                        {icon}
                    </Menu.Item>
                </Menu.Menu>
            </Menu>

            <Sidebar 
                as={Menu}
                animation='overlay'
                icon='labeled'
                inverted
                vertical
                visible={visible}
                direction='left'
                style={{
                    backgroundColor: primaryGreen,
                }}
            >
                {renderLinks()}
            </Sidebar>

        </Segment>
    )
}

export default HeaderMb;
