import React from "react";

import "./CV.css";

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

import { descrierePersonala } from '../../utils/dummyArray'

import { useParams } from "react-router-dom";

const Cv = () => {
  const { name } = useParams();

  const details = descrierePersonala.filter((person) => person.name === name);

  return (
    <main className="advancedMain">
      <Header />
      {details
        ? details.map((item, index) => {
            return (
              <section className="advancedProfile" key={index}>
                <div className="advancedProfilePicContainer">
                  <img
                    src={item.src}
                    alt="Profile Picture"
                    className="advancedProfilePic"
                  />
                  <h2>{item.name}</h2>
                </div>

                <div className="advancedDescriptionContainer">
                  <p className="advancedDescription">{item.description}</p>
                </div>
              </section>
            );
          })
        : null}
      <Footer />
    </main>
  );
};

export default Cv;
