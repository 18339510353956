import React from 'react';

import { useMediaQuery } from 'react-responsive';

import HerosectionLg from './HerosectionLg';
import HerosectionMb from './HerosectionMb';

import './Herosection.css';

const Herosection = () => {

    const md = useMediaQuery({ query: "(min-width:700px)" })
    const xs = useMediaQuery({ query: "(max-width:460px)" })

    const size = {xs, md}


    return (
        <div 
            className='heroSection-container'
        >
            {size.md ? 
                <HerosectionLg />
                : 
                <HerosectionMb />
            }
        </div>
    )
}

export default Herosection;

