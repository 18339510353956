import React from "react";

import { useMediaQuery } from 'react-responsive';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
    Segment, 
} from 'semantic-ui-react';

import { primaryGrey } from "../../../utils/colors";

import './ImageCarousel.css';

import { useLocation } from "react-router-dom";

const ImageCarousel = ({data}) => {

    const location = useLocation();

    const pathHome = location.pathname 

    const xxl = useMediaQuery({ query: "(min-width:1580px)" })
    const xl = useMediaQuery({ query: "(min-width:1300px)" });
    const md = useMediaQuery({ query: "(min-width:1240px)" });
    const s = useMediaQuery({ query: "(min-width:1120px)" });
    const xs = useMediaQuery({ query: "(min-width:1040px)" });
    const xxs = useMediaQuery({ query: "(min-width:840px)" });

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: xxl ? 3 : xxs ? 2 : 1,
        slidesToScroll: 2,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: pathHome === '/' ? false : true,
    };


    return (
        <Segment 
            style={{
                backgroundColor: primaryGrey,
                width: '80%',
                margin: 'auto',
                border: 'none',
                boxShadow: 'none',
                marginBottom: '2rem'
            }}
        >
            <Slider {...settings} style={{}}>
                { 
                    data && data.map((item, index) => (
                        <div key={index}>
                            <img 
                                src={item.src} 
                                alt={item.alt} 
                                key={item.id} 
                                width={xxl ? 400 : xl ? 480 : md ? 460 : s ? 420 : xs ? 390 : xxs ? 300 : 600 } 
                                height={300}
                            />
                        </div>
                    ))
                }
            </Slider>
        </Segment>
    )
}

export default ImageCarousel;

