import { useNavigate } from 'react-router-dom';
import './PersonCard.css';

const PersonCard = ({ data }) => {
    return (
        <div className='app-person-card-container'>
            {data && data.map((item, index) => (
                <SinglePersonCard key={index} person={item} />
            ))}
        </div>
    );
}

const SinglePersonCard = ({ person }) => {

    const navigate = useNavigate()

    return (
        <div id="login-container">
            <div className="profile-img"
                style={{
                    background: `url(${person.img})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            ></div>

            <h1>
                {person.name}
            </h1>

            <div className="description">
               {person.description}
            </div>

            <div className="social">
                <a>Facebook</a>
                <a>Instagram</a>
            </div>

            <button className='to-cv'
                onClick={() => navigate(`/cv/${person.name}`)}
            >Spre CV</button>

        </div>
    );
}

export default PersonCard;